/*.HomeContent {*/
/*    height: 100vh;*/
/*    background-color: #111922;*/
/*}*/

.HomeTitle {
    color: white;
    font-weight: bold;
    font-size: 40px;
}

.HomeSection {
    background: #070b0e;
    border: 1px solid #0a2e33;
    height: 70vh;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
}

.HomeSectionTitles {
    /*background: red;*/
    display: flex;
    justify-content: space-between;
    /*margin-top: 0px;*/
    border-bottom: 1px solid #0a2e33;
}

.HomeSectionAdd {
    background: #00a800;
    /*display: flex;*/
    width: 50px;
    height: 50px;
    /*margin-top: 2.5px;*/
    margin-top: 15px;
    margin-right: 15px;
    border: none;
    border-radius: 15px;
    color: white;
    /*justify-content: space-between;*/
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.HomeSectionAdd:hover {
    background: #00c800;
}

.HomeSectionTitle {
    /*margin-top: 10px;*/
    margin-left: 25px;
    font-weight: bold;
    color: white;
    font-size: 30px;
    margin-bottom: 10px;
}

.AddIcon {
    width: 20px;
}

.HomeSectionContent {
    display: flex;
    justify-content: flex-start;
    /*background: orange;*/
    max-width: 96%;
    max-height: 54vh;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /*padding: 10px;*/
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3vh;
    padding-bottom: 3vh;
    flex-wrap: wrap;
    /*max-height: 470px;*/
    overflow: auto;
}

.HomeSectionItem {
    width: 24.2%;
    /*background: red;*/
    background: #0e3c4a;
    /*background: #0f4147;*/
    height: 14vh;
    border-radius: 15px;
    color: white;
    /*margin-right: 15px;*/
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.HomeSectionItem:hover {
    background: #114555;
    /*border: 1px solid #00a800;*/
}

.HomeSectionItemTitle {
    font-weight: bold;
    /*text-decoration: underline;*/
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 5px;
    font-size: 22px;
}

.HomeSectionItemText {
    font-size: 17px;
    color: #e1e1e1;
}

.homeSectionContentAll {
    /*background: orange;*/
    width: 100%;
    height: 100%;
    padding-top: 0;
    /*display: flex;*/
}

.noProjectsText {
    color: #979797;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
}

@media (max-width: 480px) {
    .HomeSection {
        width: 100%;
    }

    .HomeSectionContent {
        padding-left: 0;
        padding-right: 0;
    }

    .HomeSectionItem {
        width: 45%;
        margin: 0;
        margin-left: 4.5%;
        margin-bottom: 3%;
    }

    .HomeSectionItemTitle {
        font-size: 20px;
    }

    .HomeSectionItemText {
        font-size: 13px;
        margin-top: -6%;
    }
}