.SettingsTitle {
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: 40px;
}

.SettingsSection {
    margin-top: 0;
    background: #070b0e;
    border: 1px solid #0a2e33;
    height: 70vh;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
}

.SettingsSectionContent {
    /*display: flex;*/
    /*background: orange;*/
    max-width: 96%;
    max-height: 59vh;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /*padding: 10px;*/
    padding-left: 2%;
    padding-right: 2%;
    /*padding-top: 3vh;*/
    padding-bottom: 3vh;
    margin-top: 4vh;
    flex-wrap: wrap;
    /*max-height: 470px;*/
    overflow: auto;
}

.SettingsSectionContent::-webkit-scrollbar {
    display: none;
}

.SettingsSectionItem {
    clip-path: inset(0px);
    width: 96%;
    /*background: red;*/
    background: #0e3c4a;
    /*background: #0f4147;*/
    height: 8vh;
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    /*margin-right: 15px;*/
    margin-bottom: 5px;
}

.SettingsSectionItemTitle {
    font-weight: bold;
    margin-top: 23px;
    font-size: 22px;
    /*text-decoration: underline;*/
    /*border-bottom: 1px solid #aeaeae;*/
    /*padding-bottom: 5px;*/
}

.SettingsSectionItemDescription {
    color: #bfbfbf;
    font-weight: normal;
}

/*.SettingsSectionItemText {*/
/*    color: #e1e1e1;*/
/*}*/

.SettingsInput {
    margin-top: 12px;
    /*margin-bottom: 10px;*/
    width: 75%;
    height: 5vh;
    border-radius: 5px;
    padding-left: 10px;
    background: #070b0e;
    border: 1px solid #0a2e33;
    color: white;
    text-align: center;
    font-size: 17px;
    /*font-size: 14px;*/
}

.SettingsSectionItemInputSection {
    /*background: red;*/
    /*width: 20%;*/
    display: flex;
    /*width: 15%;*/
    /*justify-content: space-between;*/
}

.SettingsSectionItemText {
    color: white;
    font-weight: bold;
    margin-left: 7px;
    margin-top: 20px;
    margin-right: -30px;
    font-size: 23px;
}

.settingsItemHidden {
    margin-bottom: -2.8vh;
}

.settingsTitlesSection {
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 2vh;
    margin-bottom: 0;
    height: 12vh;
    /*font-size: 20px;*/
}

.SettingsSaveButton {
    /*display: inline-block;*/
    margin-top: 40px;
    width: 280px;
    height: 42px;
    border-radius: 5px;
    border: none;
    background-color: #0c3a4e;
    color: white;
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /*margin-left: 20%;*/
}

.SettingsSaveButton:hover {
    background-color: #0e455c;
}

@media screen and (max-width: 600px) {
    .SettingsSectionContent {
        max-height: 60vh;
    }

    .SettingsSectionItem {
        height: 10vh;
    }

    .SettingsSectionItemTitle {
        font-size: 20px;
    }

    .SettingsSectionItemDescription {
        display: none;
        font-size: 14px;
    }

    .SettingsInput {
        height: 4vh;
        font-size: 14px;
        margin-top: 10%;
    }

    .SettingsSectionItemText {
        margin-top: 13%;
        font-size: 18px;
    }

    .SettingsSaveButton {
        font-size: 20px;
        width: 100px;
        height: 42px;
        margin-top: 28px;
    }

    .SettingsTitle {
        font-size: 30px;
    }
}