.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #192430;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Ensure it's above the backdrop */
    color: white;
    /*width: 300px;*/
    border-radius: 15px;
    width: 20%;
}

.modal .normalButton {
    background: green;
    /*height: 40px;*/
    /*width: 200px;*/
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.CreateInput {
    width: 80%;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    /*border: none;*/
    margin-bottom: 20px;
    background: #070b0e;
    border: 1px solid #0a2e33;
    color: white;
}

@media (max-width: 768px) {
    .modal {
        width: 80%;
    }
}