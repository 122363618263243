.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #192430;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Ensure it's above the backdrop */
    color: white;
    /*width: 300px;*/
    border-radius: 15px;
    width: 20%;
}

.ProjectItemAdd {
    width: 80%;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    /*border: none;*/
    margin-bottom: 20px;
    background: #070b0e;
    border: 1px solid #0a2e33;
    color: white;
}

.projectSelectHide {
    display: none;
    visibility: hidden;
}

.modal .normalButton {
    background: green;
    /*height: 40px;*/
    /*width: 200px;*/
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.modal h1 {
    margin-bottom: 0;
}

.deleteItemDescription {
    color: #e1e1e1;
    font-size: 20px;
    /*margin-top: 10px;*/
    /*margin-bottom: 0;*/
    margin-top: 0;
}

.deleteItemPrice {
    font-weight: bold;
}

.deleteItemCancel {
    background: grey;
    /*height: 40px;*/
    /*width: 200px;*/
    padding: 12px 20px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    width: 80%;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.deleteItemCancel:hover {
    background: #959595;
}

.deleteItemConfirm {
    background: #a50000;
    /*height: 40px;*/
    /*width: 200px;*/
    padding: 12px 20px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    width: 80%;
    margin-top: 50px;
    transition: background-color 0.3s ease;
}

.deleteItemConfirm:hover {
    background: #c10000;
}

@media (max-width: 480px) {
    .modal {
        width: 80%;
    }
}