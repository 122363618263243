/*.HeaderMain {*/
/*  !*background-color: #282c34;*!*/
/*  !*color: white;*!*/
/*  !*text-align: center;*!*/
/*  !*padding: 1rem;*!*/
/*  background-color: orange;*/
/*  !*position: fixed;*!*/
/*  !*top: 1.5%;*!*/
/*  !*margin-top: 0;*!*/
/*  !*height: 10vh;*!*/
/*  !*width: 100%;*!*/
/*  !*padding: 10px;*!*/
/*  width: 20%;*/
/*  margin-top: 5px;*/
/*  padding-bottom: 4px;*/
/*  padding-top: 0.5px;*/
/*  border-radius: 20px;*/
/*  margin-left: auto;*/
/*  margin-right: auto;*/
/*}*/

.nav {
  margin-top: 10px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  /*background-color: #fff;*/
  /*background-color: orange;*/
  padding: 0 15px;
  border-radius: 40px;
  /*box-shadow: 0 10px 40px rgba(159, 162, 177, .8);*/
  border: 1px solid #0a2e33;
  background-color: #0c1219;
  color: white;
}

.second {
  position: absolute;
  right: 0.3%;
  cursor: pointer;
}

.LogouIcon {
  width: 28px;
  padding: 15px;
}

.nav-item {
  color: white;
  padding: 15px;
  text-decoration: none;
  transition: .3s;
  margin: 0 6px;
  z-index: 1;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  font-size: 25px;

  /*&:before {*/
  /*  content: "";*/
  /*  position: absolute;*/
  /*  bottom: -6px;*/
  /*  left: 0;*/
  /*  width: 100%;*/
  /*  height: 5px;*/
  /*  background-color: #dfe2ea;*/
  /*  border-radius: 8px 8px 0 0;*/
  /*  opacity: 0;*/
  /*  transition: .3s;*/
  /*}*/
}

.logout {
  color: #ff2b2b;
}

/*.nav-item:not(.is-active):hover:before {*/
/*  opacity: 1;*/
/*  bottom: 0;*/
/*}*/

.nav-item.is-active {
  font-weight: bold;
  text-decoration: underline;
}


.nav-item:not(.is-active):hover {
  color: #d8d8d8;
}

.nav-item:is(.logout):hover {
  color: #ff0000;
}

@media (max-width: 480px) {
  /* Mobile-friendly HeaderMain */
/*.nav {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  margin-right: 50px;*/
/*}*/
  .navigation-section {
    width: 80%;
    margin-right: auto;
    /*margin-left: 0;*/
    margin-left: -4.5%;
  }
  .second {
    /*position: relative;*/
    /*margin-top: -15%;*/
    right: 2.5%;
    cursor: pointer;
  }
    .nav-item {
        /*padding: 10px;*/
        font-size: 20px;
        /*margin: 0 30px;*/
    }
}
