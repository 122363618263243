.LoginMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    background-color: #111922;
    color: white;
}

.LoginMenuTitle {
    margin-top: 50px;
    font-weight: bold;
    font-size: 60px;
}

.LoginInput {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 250px;
    height: 38px;
    border-radius: 5px;
    /*border: none;*/
    padding-left: 10px;
    background: #070b0e;
    border: 1px solid #0a2e33;
    color: white;
}

.LoginButton {
    margin-top: 30px;
    width: 280px;
    height: 42px;
    border-radius: 5px;
    border: none;
    background-color: #0c3a4e;
    color: white;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.LoginButton:hover {
    background-color: #0e455c;
}

/*.LoginContent {*/
/*    height: 100vh;*/
/*    background-color: #111922;*/
/*}*/

.LoginBackground {
    background: #070b0e;
    border: 1px solid #0a2e33;
    padding-bottom: 70px;
    border-radius: 10px;
    margin-top: 60px;
}

.hidden-seo {
    position: absolute;
    top: -9999px;  /* Moves the content off-screen */
    left: -9999px; /* Moves the content off-screen */
    visibility: hidden;  /* Ensures content is not visible */
}