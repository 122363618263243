.ProjectTitle {
    color: white;
    font-weight: bold;
    font-size: 40px;
    margin-top: 8vh;
}

.ProjectSection {
    background: #070b0e;
    border: 1px solid #0a2e33;
    height: 70vh;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
}

.ProjectSectionTitles {
    /*background: red;*/
    display: flex;
    justify-content: space-between;
    /*margin-top: 0px;*/
    border-bottom: 1px solid #0a2e33;
}
.ProjectSectionDescription {
    color: #cfcfcf;
    margin-top: 0;
    font-size: 20px;
    /*margin-left: -9px;*/
    margin-left: 0;
}

.ProjectSectionTitle {
    /*margin-top: 10px;*/
    margin-left: 0;
    font-weight: bold;
    color: white;
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 12px;
}

.ProjectSectionTitlesBox {
    /*background: red;*/
    margin-left: 25px;
    text-align: left;
    height: 10.5vh;
}

.noProjectDataText {
    color: #979797;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
}

.ProjectSectionAdd {
    background: #00a800;
    /*display: flex;*/
    width: 50px;
    height: 50px;
    /*margin-top: 2.5px;*/
    margin-top: 15px;
    margin-right: 15px;
    border: none;
    border-radius: 15px;
    color: white;
    /*justify-content: space-between;*/
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.ProjectSectionAdd:hover {
    background: #00c800;
}

.ProjectSectionResult {
    color: white;
    font-size: 20px;
    /*margin-left: 25px;*/
    /*margin-top: -20px;*/
    /*margin-bottom: 10px;*/
    background: #007ac6;
    border: none;
    border-radius: 15px;
    /*margin-top: 15px;*/
    /*margin-right: 15px;*/
    padding-top: 14px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
    font-weight: bold;
}

.ProjectSectionResultMobile {
    display: none;
}

.ProjectSectionContent {
    display: flex;
    justify-content: flex-start;
    /*background: orange;*/
    max-width: 96%;
    max-height: 54vh;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /*padding: 10px;*/
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3vh;
    padding-bottom: 3vh;
    flex-wrap: wrap;
    /*max-height: 470px;*/
    overflow: auto;
}

.projectSectionContentAll {
    /*background: orange;*/
    width: 100%;
    height: 100%;
    padding-top: 0;
    /*display: flex;*/
}

.ProjectSectionItem {
    width: 19.2%;
    /*background: red;*/
    background: #0e3c4a;
    /*background: #0f4147;*/
    height: 25vh;
    border-radius: 15px;
    color: white;
    /*margin-right: 15px;*/
    margin: 5px;
    /*cursor: pointer;*/
    transition: background-color 0.3s ease;
}

.ProjectSectionItemTitles {
    /*background: red;*/
    display: flex;
    justify-content: space-between;
    /*margin-top: 0px;*/
    border-bottom: 1px solid #aeaeae;
    /*padding-bottom: 5px;*/
    /*padding-bottom: -55px;*/
    padding-left: 5px;
    padding-right: 5px;
}

.ProjectSectionItemTitle {
    font-weight: bold;
    font-size: 20px;
    /*text-decoration: underline;*/
    /*border-bottom: 1px solid #aeaeae;*/
    /*padding-bottom: 5px;*/
}

.ProjectSectionItemCount {
    font-size: 20px;
}

.ProjectSectionItemText {
    color: #e1e1e1;
    /*font-size: 20px;*/
}

.ProjectSectionItemPrice {
    color: #e1e1e1;
    font-weight: bold;
    font-size: 28px;
}

.ProjectSectionItemEdit {
    background: rgba(117, 117, 117, 0.54);
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, font-weight 0.3s ease;
    font-size: 17px;
}

.ProjectSectionItemEdit:hover {
    background: rgba(117, 117, 117, 0.74);
    font-weight: bold;
}

.ProjectSectionItemDelete {
    background: rgba(255, 0, 0, 0.54);
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, font-weight 0.3s ease;
    font-size: 17px;
    font-weight: normal;
}

.ProjectSectionItemDelete:hover {
    background: rgba(255, 0, 0, 0.74);
    font-weight: bold;
}

.ProjectPercentageTable2 {
    display: none;
}

.ProjectPercentageTable {
    background: #0e3c4a;
    width: 16%;
    height: 16vh;
    /*display: flex;*/
    /*justify-content: center;*/
    align-items: center;
    border-radius: 15px;
    position: absolute;
    right: 7.5%;
    top: 8.5vh;
}

.ProjectPercentageTableTitle {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
}

.ProjectPercentageTableContent {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}

.ProjectPercentageTableButton {
    background: #00a800;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.ProjectPercentageTableButton:hover {
    background: #00c800;
}

.ProjectPercentageTableButtonConfirm {
    background: #0087da;
    /*width: 50px;*/
    height: 35px;
    border: none;
    border-radius: 12px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin-top: 0.5vh;
    width: 90%;
}

.ProjectPercentageTableButtonConfirm:hover {
    background: #00a8ff;
}

.ProjectPercentageTableInput {
    width: 60px;
    height: 50px;
    text-align: center;
    border-radius: 12px;
    border: none;
    background: #00a800;
    /*color: white;*/
    color: white;
    font-weight: bold;
}

.ProjectPercentageTableInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.ProjectPercentageTableInput::placeholder {
    color: white;
}


@media (max-width: 480px) {
    .ProjectSection {
        width: 100%;
    }

    .ProjectSectionContent {
        padding-left: 0;
        padding-right: 0;
    }

    .ProjectSectionItem {
        width: 45%;
        margin: 0;
        margin-left: 4.5%;
        margin-bottom: 3%;
    }

    .ProjectSectionItemTitle {
        font-size: 17px;
    }

    .ProjectSectionItemText {
        font-size: 15px;
    }

    .ProjectSectionItemPrice {
        font-size: 20px;
    }

    .ProjectSectionItemEdit {
        font-size: 15px;
    }

    .ProjectSectionItemDelete {
        font-size: 15px;
    }

    .ProjectSectionItemCount {
        font-size: 13px;
        margin-top: 2%;
    }

    .ProjectSectionResult {
        display: none;
    }

    .ProjectTitle {
        margin-bottom: 20px;
    }

    .ProjectSectionResultMobile {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 10px;
        display: block;
        color: white;
        font-size: 20px;
        /*margin-left: 25px;*/
        /*margin-top: -20px;*/
        /*margin-bottom: 10px;*/
        background: #007ac6;
        border: none;
        border-radius: 15px;
        /*margin-top: 15px;*/
        /*margin-right: 15px;*/
        padding-top: 14px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        /*margin-right: 15px;*/
        font-weight: bold;
    }

    .ProjectResultTableMobile {
        /*background: red;*/
        width: 78%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
    }

    .ProjectPercentageTable {
        display: none;
    }

    .ProjectPercentageTable2 {
        display: block;
        background: #0e3c4a;
        width: 80%;
        height: 20vh;
        /*display: flex;*/
        /*justify-content: center;*/
        align-items: center;
        border-radius: 15px;
        margin-left: auto;
        margin-right: auto;
        /*position: absolute;*/
        /*right: 0;*/
        /*top: 10.5vh;*/
        margin-bottom: 2vh;
    }

    .ProjectPercentageTableTitle {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .ProjectPercentageTableButton {
        margin-top: 0;
        width: 55px;
        height: 55px;
    }

    .ProjectPercentageTableInput {
        width: 70px;
        height: 49px;
    }
}